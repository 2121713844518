<template>
    <div class="" style="padding: 0">
        <div class="logo">
            <img src="../assets/shouye/logo@3x.png" alt="" />
            <div>昱澄信息</div>
        </div>
        <div class="nav">
            <van-tabs
                title-inactive-color="#fff"
                v-model="active"
                title-active-color="red"
            >
                <van-tab title="首页" to="/"> </van-tab>
                <van-tab title="产品" to="/products"> </van-tab>
                <van-tab title="服务" to="/serve"> </van-tab>
                <van-tab title="关于我们" to="/AboutUs"> </van-tab>
                <van-tab title="加入我们" to="/JoinUs"> </van-tab>
            </van-tabs>
        </div>
    </div>
</template>

<script>
export default {
    name: "home",
    data() {
        return {
            active: 0,
        };
    },
    watch: {
        $route(val) {
            switch (val.matched[0].path) {
                case "/":
                    this.active = 0;
                    break;
                case "/products":
                    this.active = 1;
                    break;
                case "/serve":
                    this.active = 2;
                    break;
                case "/AboutUs":
                    this.active = 3;
                    break;
                case "/JoinUs":
                    this.active = 4;
                    break;
                default:
                    break;
            }
        },
    },
};
</script>
<style scoped>
.logo {
    display: flex;
    padding-top: 30px;
    padding-bottom: 20px;
    padding-left: 23px;
}

.logo > img {
    padding-right: 10px;
    height: 26px;
    vertical-align: top;
}

.logo > div {
    color: white;
    font-size: 20px;
}

:deep .van-tabs__wrap {
    height: 52px !important;
}

:deep .van-tabs__nav {
    background-color: #171717 !important;
}

:deep .van-tab__pane {
    color: white;
    background-color: black;
}

:deep .van-tab__text--ellipsis {
    font-size: 10px;
}
</style>
