<template>
  <div class="container">
    <!-- echarts图片 -->
    <van-image
        fit="cover"
        :src="require('@/assets/xiaochengzhizhen/bg_chanping4@3x.png')"
    />
    <!-- 列表 -->
    <div class="list">
      <div v-for="items in header_list" >
        <div class="item">
          <van-image
              width="1rem"
              height="1rem"
              :src="require('@/assets/TG/ic_youdian@3x.png')"
          ></van-image>
          <p>{{items.content}}</p>
        </div>
      </div>
    </div>

    <!-- 我们的优势 -->
    <div class="our-advantage">
      <div class="title">
        <span class="our-title font-sizea">我们的优势</span>
        <span>Our advantage</span>
      </div>
      <van-grid :border="false" :column-num="3" style="margin: 20px 0">
        <van-grid-item v-for="item in list">
          <van-image :src="item.img"  width="50"  height="50"  fit="contain" />
          <div class="our-title">{{ item.title }}</div>
          <p class="font-ps">{{ item.content }}</p>
        </van-grid-item>
      </van-grid>
    </div>
  </div>
</template>

<script>
export default {
    name: "xiaocheng",
  data(){
      return{
        list:[

        ],
        header_list:[],
      }
  },
  methods:{
    getList() {
      this.$http.get('/static/xiaocheng.json').then((res) => {
        this.list = res.data.list
        this.header_list =  res.data.header_list
      })
    }
  },
  created() {
    this.getList()
 
  }
};
</script>

<style scoped>
.container {
  color: #fff;
  padding: 0 12pt;
}
.container .list {
  margin: 15pt 0;
}
.container .list .item {
  display: flex;
  align-items: center;
}
.container .list .item .van-image {
  padding: 0 9pt;
}
.container .list .item p span {
  font-weight: 600;
  color: #d6323d;
}
.container .our-advantage .title {
  height: 30pt;
  background-image: url("../../assets/shouye/bg_biaoti@3x.png");
  background-size: 100% 30pt;
  background-repeat: no-repeat;
  display: flex;
  align-items: flex-end;
  padding: 7.5pt 7.5pt;
  box-sizing: border-box;
}
.container .our-advantage .our-title {
  font-size: 11.25pt;
  font-weight: bold;
  text-align: center;
  height:20px;
}
.font-ps{
  font-size: 6.67pt;
}
.container .our-advantage .title span:last-child {
  font-size: 7.5pt;
  margin-left: 7.5pt;
  color: #7c5255;
}
:deep .van-grid-item__content {
  background-color: transparent;
  padding: 0 6pt;
}
.our-advantage .van-image {
  width: 40%;
}
.van-grid-item__content .our-title {
  margin: 15pt 0;
}
.van-grid-item__content p {
  color: #777;
  text-align: center;
  height: 37.5pt;
}
</style>
