import { render, staticRenderFns } from "./Yucheng.vue?vue&type=template&id=501fbd49&scoped=true&"
import script from "./Yucheng.vue?vue&type=script&lang=js&"
export * from "./Yucheng.vue?vue&type=script&lang=js&"
import style0 from "./Yucheng.vue?vue&type=style&index=0&id=501fbd49&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "501fbd49",
  null
  
)

export default component.exports