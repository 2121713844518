<template>
    <div id="app">
        <HeaderTop></HeaderTop>
        <router-view />
    </div>
</template>
<script>
import HeaderTop from "@/components/HeaderTop.vue";
export default {
    components: {
        HeaderTop,
    },
};
</script>
<style>
* {
    font-size: 7.5pt;
}
body {
    margin: 0;
    padding: 0;
    background: #000;
    width: 100%;
    height: 100%;
}
</style>
