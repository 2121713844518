import Vue from "vue";
import VueRouter from "vue-router";
// 引入产品页面
import TG from "@/views/Products/TG.vue";
import Liansc from "@/views/Products/Liansc.vue";
import Yucheng from "@/views/Products/Yucheng.vue";
import Xiaocheng from "@/views/Products/Xiaocheng.vue";
import Haina from "@/views/Products/Hainabc.vue";
// import  Home from "@/views/Home/Index.vue";
// import  Product  from "@/views/Products/Index.vuew";
import ServeS from "@/views/Serve";
import Homes from "@/views/Home";
Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Homes,
    },
    {
        path: "/serve",
        name: "服务",
        component: ServeS,
    },
    {
        path: "/AboutUs",
        name: "关于我们",
        component: () => import("@/views/AboutUs"),
    },
    {
        path: "/products",
        name: "产片",
        component: () => import("@/views/Products"),
        children: [
            {
                path: "",
                name: "TG",
                component: TG,
            },
            {
                path: "liansc",
                name: "链审查",
                component: Liansc,
            },
            {
                path: "yucheng",
                name: "昱澄哨兵",
                component: Yucheng,
            },
            {
                path: "xiaocheng",
                name: "小澄智侦",
                component: Xiaocheng,
            },
            {
                path: "haina",
                name: "海纳百川",
                component: Haina,
            },
        ],
    },
    {
        path: "/JoinUs",
        name: "加入我们",
        component: () => import("@/views/JoinUs"),
    },
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
});

export default router;
