import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Vant from 'vant';
import 'vant/lib/index.css';
import axios from 'axios'
import MetaInfo from 'vue-meta-info'

Vue.prototype.axiso = axios
// axios.defaults.baseURL = 'http://localhost:8080/api'
Vue.prototype.$http = axios

Vue.use(Vant,MetaInfo);
// Vue.use(MetaInfo)

Vue.config.productionTip = false
new Vue({
    el: '#app',
    router,
    render: h => h(App),
    // components: { App },
    // template: '<App/>',
    mounted () {
        document.dispatchEvent(new Event('render-event'))
    }
}).$mount('#app')
