<template >
    <div >
        <div >
            <div class = "title" >
                <div >我们的服务</div >
            </div >
            <div class = "title-text" >
                <div >
                    昱澄信息提供案件前、中、后期全链条虚拟货币资产追踪溯源和调查取证服务，包含线索获取、资金扩线、匿名身份识别、线索研判、协助
                    调证、现场支持、模式分析、证据固证（司法鉴定）、定制化服务等。
                </div >
            </div >
        </div >
        <div >
            <div >
                <div class = "list" v-for = "i in list" >
                    <div :style = "{'background-image':'url('+i.img1+')'}" >
                        <img :src = "i.img2" >
                        {{ i.img }}
                    </div >
                    <div >
                        <div >{{ i.title }}</div >
                        <div >
                            {{ i.content }}
                        </div >
                    </div >
                </div >
            </div >
        </div >
    </div >
</template >

<script >
    export default {
        name: "serve",
        data() {
            return {
                list: []
            }
        },
        methods: {},
        created() {
            this.$http.get(`/static/serve.json`).then(res => {
                this.list = res.data
            })
        }
    };
</script >

<style scoped >


    .title {
        display: flex;
        justify-content: center;
    }

    .title > div {
        margin-top: 15pt;
        width: 142.5pt;
        height: 45pt;
        line-height: 37.5pt;
        text-align: center;
        background-image: url("@/assets/fuwu/bg_fuwu@3x.png");
        background-size: 100% 100%;
        color: #ECECEC;
        font-size: 13.33pt;
        font-weight: bold;

    }

    .title-text {
        display: flex;
        /* font-size:8pt; */
        justify-content: center;
    }

    .title-text > div {
        /* width: 80%; */
        color: #fefefe;
        font-size: 8pt;
        text-align: center;
        margin: 0 20.67pt 16pt 28pt;
        opacity: 0.8;
    }

    .list {
        display: flex;
        /*justify-content: space-around;*/
        display: table;
    }

    .list > div:nth-child(1) {
        flex: 2;
        height:119.67pt;
        width: 98.67pt;
        /*background-image: url('@/assets/服务/bg_xunibi_sel@3x.png');*/
        background-size: 100% 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 6.33pt 9.67pt 3pt 18.33pt;
    }

    .list > div:nth-child(1) > img {
        width: 30pt;
        height: 30pt;
    }

    .list > div:nth-child(2) {
        flex: 5;
        display: table-cell;
    vertical-align: middle;
    }

    .list > div:nth-child(2) > div:nth-child(1) {
        /* margin-top: 26pt; */
        margin-top: 9.33pt;
        margin-bottom: 4.5pt;
        font-size: 10pt;
        font-weight: bold;
        color: white;
    }

    .list > div:nth-child(2) > div:nth-child(2) {
        /*width: 50%;*/
        font-size: 6pt;
        color: #ababab;
        margin-right: 15pt;
        opacity: 0.8;
    }
</style >
