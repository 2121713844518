<template >
    <div >
        <div class = "img" >
            <div class = "img-a" >
                <span >CYBER</span >
                <span >SECURITY PRACTITIONER</span >
            </div >
            <!--          <div class="img-b">-->
            <!--            <div>-->
            <!--              <span>[ 网络安全践行者 ]</span>-->
            <!--              <span>CYBER</span>-->
            <!--              <span>SECURITY PRACTITIONER</span>-->
            <!--            </div>-->
            <!--            <div>-->
            <!--              <span>科技助力正义</span>-->
            <!--              <span>THECHNOLOGY FOR JUSTICEL</span>-->
            <!--            </div>-->
            <!--          </div>-->
        </div >
        <div class = "column" >
            <div @click = "tiaozhuan(i.title)" v-for = "i in nav" >
                <img :src = "i.img" alt = "" >
                <div >{{ i.title }}</div >
            </div >
        </div >
        <div class = "title" >
            <div style = "font-size: 15px" >我们的服务
                <span style = "font-size: 10px" >Our service</span >
            </div >
        </div >
        <div class = "list" >
            <div v-for = "i in list" @click = "tiaozhuan2" >
                <div ><img :src = "i.img" alt = "" ></div >
                <div >{{ i.title }}</div >
            </div >
        </div >
    </div >
</template >

<script >
    export default {
        name: "home",
        data() {
            return {
                nav: [
                    {
                        img: require("@/assets/shouye/ic_tg@3x.png"),
                        title: "TG"
                    },
                    {
                        img: require("@/assets/shouye/ic_lianshencha@3x.png"),
                        title: "链审查"
                    },
                    {
                        img: require("@/assets/shouye/ic_shaobin@3x.png"),
                        title: "昱澄哨兵"
                    },
                    {
                        img: require("@/assets/shouye/ic_zhizhen@3x.png"),
                        title: "小澄智侦"
                    },
                    {
                        img: require("@/assets/shouye/ic_haina@3x.png"),
                        title: "海纳百川"
                    }
                ],
                list: [
                    {
                        img: require("@/assets/shouye/ic_wangzha@3x.png"),
                        title: "网诈案件支撑"
                    },
                    {
                        img: require("@/assets/shouye/ic_xunibi@3x.png"),
                        title: "虚拟币溯源"
                    },
                    {
                        img: require("@/assets/shouye/ic_zhencha@3x.png"),
                        title: "侦察技术支持"
                    }
                ]
            };
        },
        methods: {
            tiaozhuan(i) {
                console.log(i)
                switch (i) {
                    case "TG":
                        this.$router.push('/products?tag=1')
                        break;
                    case "链审查":
                        this.$router.push('/products/liansc?tag=2')
                        break;
                    case "昱澄哨兵":
                        this.$router.push('/products/yucheng?tag=3')
                        break;
                    case "小澄智侦":
                        this.$router.push('/products/xiaocheng?tag=4')
                        break;
                    case "海纳百川":
                        this.$router.push('/products/haina?tag=5')
                        break;
                    default:
                        break;
                }
            },
            tiaozhuan2() {
                this.$router.push('/serve')
            }
        },
        created() {
            // axios.get('/home-list.json').then(res => {
            //     console.log(res)
            // })
            // axios.get('/home-nav.json').then(res => {
            //     console.log(res)
            // })
        }
    };
</script >

<style scoped >
    .img {

        margin-top: 15pt;
        display: flex;
        flex-direction: column;
        background-image: url("../../assets/shouye/banner@3x.png");
        background-size: 100% 100%;
        /*width: 90%;*/
        height: 143pt;
        font-size: 7.5pt;
        margin: 11pt;
        color: #fff;
    }

    .img-a {
        height: 37.5pt;
        display: flex;
        flex-direction: column;
        margin: 45pt auto;
    }

    .img-a span:nth-child(1) {
        font-size: 25pt;
    }

    .img-a span {
        font-size: 17pt;
    }

    /*.img-b {*/
    /*    display: flex;*/
    /*}*/

    /*.img-b div {*/
    /*    display: flex;*/
    /*    flex-direction: column;*/
    /*}*/

    /*.img-b div span {*/
    /*    font-size: 5px;*/
    /*}*/


    .column {
        display: flex;
        justify-content: center;
        margin-top: 15pt;
        /*width: 90%;*/
        padding: 0 20px;
    }

    .column > div {
        margin: 0 3.75pt;
        width: 80%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .column > div > div {
        font-size: 6pt;
        font-weight: 400;
        text-align: center;
        color: #ffffff;
    }

    .column img {
        width: 39pt;
        height: 39pt;
    }

    .title {
        display: flex;
        justify-content: center;
        margin: 0 auto;
        padding: 15pt 0;
        width: 90%;

    }

    .title > div {
        color: white;
        background-image: url("../../assets/shouye/bg_biaoti@3x.png");
        background-size: 100% 100%;
        width: 100%;
        height: 20pt;
        font-size: 7.5pt;
        line-height: 20pt;
        padding: 0 7.5pt;

    }

    .title span {
        font-size: 4.5pt;
    }

    .list {
        display: flex;
        justify-content: center;
        width: 100%;

    }

    .list > div {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .list > div:nth-child(1) {
        padding-left: 15pt;
    }

    .list > div:nth-child(3) {
        padding-right: 15pt;
    }

    .list > div > div:nth-child(1) {
        width: 80%;
        height: 79.5pt;
        background: #2b0a0c;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .list > div > div:nth-child(1) > img {
        width: 25pt;
        height: 25.5pt;
    }

    .list > div > div:nth-child(2) {
        width: 80%;
        height: 33.7pt;
        line-height: 33.7pt;
        background: #811e25;
        font-size: 7.5pt;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
    }
</style >
